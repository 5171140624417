export default function Page4({Question}: {Question: ({question, name}: {question: string, name: "q1" | "q2" | "q3" | "q4" | "q5" | "q6" | "q7" | "q8" | "q9" | "q10" | "q11" | "q12" | "q13" | "q14" | "q15" | "q16" | "q17" | "q18" | "q19" | "q20" | "q21" | "q22" | "q23" | "q24"}) =>    JSX.Element}){
    const questions: {name: string, question: string}[] = [
        {name: 'q14', question: 'Saya percaya Tuhan telah membuat panduan yang lengkap dan tepat dalam mengarahkan umat manusis menuju kebahagiaan dan keselamatan sehingga manusia wajib  mematuhinya'},
        {name: 'q15', question: 'Tidak ada satupun kitab ajaran agama yang memuat kebenaran yang mendasar mengenai kehidupan'},
        {name: 'q16', question: 'Setan adalah penyebab utama kejahatan di muka bumi yang selalu berperang melawan Tuhan'},
        {name: 'q17', question: 'Menjadi orang baik lebih penting ketimbang beriman kepada Tuhan dan beragama yang benar'},
        {name: 'q18', question: 'Saya percaya ada serangkaian ajaran agama tertentu yang sangat benar dan tidak dapat dibahas lebih dalam karena ajaran tersebut merupakan sumber terdalam dan paling mendasar yang diberikan Tuhan kepada umat manusia'},
    ]


    return (<>
        <ol start={14} style={{paddingLeft : 'inherit'}}>
            {questions.map((quest,key) => {
                return(<li key={key}>
                    <Question name={quest.name as 'q14' | 'q15' | 'q16' | 'q17' | 'q18'} question={quest.question} />
                </li>)
            })}
        </ol>

    </>)
}